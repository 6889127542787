.note-iconfont {
  padding: $g-spacing-large 0;
  font-size: ($font--size--2)/2;
  color: #757575;
}

.note-iconfont a,
.note-download a {
  color: $alt-3;
}
