.cta__button--1 {

  $lighten-percent: 10%;

  border: 0;

  background-color: $alt-1;
  color: $white;

  padding: $g-spacing-medium $g-spacing-regular;
  margin: $g-spacing-medium 0 $g-spacing-large 0;

  font-family: $g-body-font;
  font-weight: bold;
  font-size: ($doc-font-size)*0.9;

  transition: background-color $g-animation-time-fast, color $g-animation-time-fast;

  &:hover {
    cursor: pointer;
    background-color: $alt-2;
    color: lighten(choose-contrast-color($alt-2), $lighten-percent);
  }

  &#{&}--alt-1 {
    background-color: $alt-1;
    color: choose-contrast-color($alt-1);

    &:hover {
      background-color: lighten($alt-1, $lighten-percent);
      color: lighten(choose-contrast-color($alt-1), $lighten-percent);
    }
  }

  &#{&}--alt-2 {
    background-color: $alt-1;
    color: choose-contrast-color($alt-1);

    &:hover {
      background-color: lighten($alt-2, $lighten-percent);
      color: lighten(choose-contrast-color($alt-2), $lighten-percent);
    }
  }

  &#{&}--small {
    padding: $g-spacing-tiny $g-spacing-small;
    font-size: $font--size--7;
  }
}

.cta__button--2 {

  $lighten-percent: 10%;

  border: 0;

  background-color: $alt-3;
  color: $white;

  padding: $g-spacing-medium $g-spacing-regular;
  margin: $g-spacing-medium $g-spacing-small $g-spacing-large $g-spacing-small;

  font-family: $g-body-font;
  font-weight: bold;
  font-size: ($doc-font-size)*0.9;

  transition: background-color $g-animation-time-fast, color $g-animation-time-fast;

  &:hover {
    cursor: pointer;
    background-color: $alt-4;
    color: $white;
  }

  &#{&}--alt-1 {
    background-color: $alt-3;
    color: choose-contrast-color($alt-3);

    &:hover {
      background-color: lighten($alt-4, $lighten-percent);
      color: $white;
    }
  }

  &#{&}--alt-2 {
    background-color: $alt-3;
    color: choose-contrast-color($alt-3);

    &:hover {
      background-color: lighten($alt-4, $lighten-percent);
      color: lighten(choose-contrast-color($alt-4), $lighten-percent);
    }
  }

  &#{&}--small {
    padding: $g-spacing-tiny $g-spacing-small;
    font-size: $font--size--7;
  }
}

section .cta__button--1 {
  color: $white;
  display: inline-block;
  text-decoration: none;
}
