.download__single--1st-row picture {
  height: 70px;
}

.download__single--2nd-row picture {
  height: 200px;
}

.download__single--3rd-row picture {
  height: 70px;
}

.download__single {
  margin-bottom: $g-spacing-large;

  picture {
    display: block;
    margin: $g-spacing-regular 0 5px 0;
    position: relative;

    @include tablet--up {
      margin: $g-spacing-regular $g-spacing-regular 0 $g-spacing-regular;
    }
  }

  & .margin-bottom {
    margin-bottom: 25px;

    @include tablet--up {
      margin-bottom: 0;
    }
  }

  & .margin-bottom-2 {
    margin-bottom: 15px;
  }
}

.download__campaign picture {
  max-width: 260px;
  margin: 0 auto;

  @include tablet--up {
    margin: 0 auto;
  }
}

.download__link {
  color: $alt-3;
}
