.css-slider {
  justify-content: center;
  margin: $g-spacing-extra-large 0 $g-spacing-smaller 0;

  * {
    position: relative;
    list-style: none;
  }
}

.div-slider {
  .css-slider__slide {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .css-slider__thumbnail {
    height: 50%;
  }
  div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
  }
  .css-slider__slide--fit > div {
    background-size: contain;
    background-color: darken($background-color, 10%);
  }
}

// thumbnail
.css-slider__thumbnail-wrapper {
  margin-top: 5px;
  height: 130px;
}

.css-slider__thumbnail {
  display: flex;
  flex-direction: row;
  float: left;
  width: 20%;

  img {
    display: inline;
    width: 100%;
    height: 60px;
    object-fit: cover;
  }
}

// slides
.css-slider__slide-wrapper {
  overflow: hidden;
  height: 55vw;
  max-height: 400px;
}

.css-slider__slide {
  max-width: 600px;
  height: auto;
  position: absolute;
  z-index: 1;

  top: 50%;
  transform: translateY(-50%);

  &#{&}--active {
    z-index: 3;
    -webkit-animation: slide 1s 1;
  }

  &:not(#{&}--active) {
    -webkit-animation: hidden 1s 1;
  }

  picture, img {
    max-width: 600px;
    object-fit: cover;
  }
}

@media screen and (max-height: 500px) {
  .css-slider__slide-wrapper {
    height: 68%;
  }
}

@keyframes slide {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes hidden {
  0% {
    z-index: 2;
  }
  100% {
    z-index: 2;
  }
}
