.section-claim {
  padding-bottom: $g-spacing-extra-large;
}

.claim__headline,
.claim__subline {
  color: $alt-4;
  font-family: $g-font-secondary;
  font-weight: 400;
  font-size: ($font--size--2)*1.2;
  padding: $g-spacing-regular 0 0 0;
  margin: 0;
  line-height: 1.2;
}

.claim__subline {
  font-size: ($font--size--2)*1.7;
  padding: 0 0 $g-spacing-small 0;
}

.claim__text {
  font-size: ($font--size--2)/2;
  padding: 0;
  margin: 0;
  margin-bottom: $g-spacing-smaller;
}

.claim__link {
  color: $alt-3;
  text-decoration: underline;
}
