// making the navigationbar work on mobile without breaking social icons into two lines
.navigationbar .l-33-33-33--no-collapse{
  @include phone{
    grid-template-columns: 0.3fr 1fr 0.5fr;
  }
}

// don't cut off iframe on small width devices
// better option probably would be to have full width sections on mobile
/*
the 86vw stem from _layout.css
.l-container {
  width: 87vw;
 */
.section--crowdfunding{
  @include phone{
    margin: 0 (100vw - 87vw) / 2vw;

  }
}
