.l-container--projects {
  margin-top: 20px;
}

.project {
  position: relative;

  > a {
    text-decoration: none;
    color: black;
    hyphens: auto;
  }

  > ul,
  > ol {
    list-style-type: none;
    text-align: left;

    > li > a {
      color: black;

      > p {
        padding-bottom: 0.2em;

        &:first-of-type {
          padding-right: 0.5em;
          float: left;
        }
      }
    }
  }

  > ol {
    list-style-type: decimal;
    padding-left: 20px;

    > li > a {
      text-decoration: underline;
      pointer-events: auto;

      > p {
        padding-left: .1em;

        &:first-of-type {
          float: none;
        }
      }
    }
  }
}

.project__anker {
  position: absolute;
  top: -30px;
}

.project_navigation {
  z-index: 5;

  > .project_navigation_button {
    height: 50px;
    width: 50px;
    display: block;
    bottom: 40%;
    position: fixed;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: white;
    background-color: $alt-1;

    picture {
      margin: 5px;
      width: 40px;
      display: inherit;
    }

    &--close {
      bottom: calc(40% + 60px);
      left: 0;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}

.rotate--180 {
  transform: rotate(180deg);
}
