.section-imprint {
  margin: $g-spacing-large auto 0;
  max-width: 400px;
  padding-bottom: $g-spacing-large;

  @include tablet--up {
    text-align: left;
    max-width: none;
  }
}
.imprint__company{
  padding-bottom: $g-spacing-extra-large * 2;

}
.imprint__list {
  padding-bottom: $g-spacing-small;
}

.imprint__list__item {
  list-style: none;
  font-size: ($font--size--2)/2;
}

.imprint__logos img {
  padding-bottom: $g-spacing-medium;
}

.imprint__logo--1 img {
  max-width: 220px;
}

.imprint__logo--2 img {
  max-width: 140px;
}