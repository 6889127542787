.l-container {
  width: 87vw;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  @include tablet--up {
    &--projects {
      max-width: unset;

      columns:3;
      column-gap: 1em;

      > .project {
        width: 96%;
        padding: 2%;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid-column;
        display:table;
      }
    }
  }
}

.l-container--narrow {
  max-width: $g-max-width-narrow;
}

.l-50-50 {
  @include tablet--up {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.l-50-50--no-collapse {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.l-33-33-33 {
  @include tablet--up {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.l-33-33-33--no-collapse {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.l-gap--large {
  @include tablet--up {
    grid-gap: $g-spacing-large;
  }
}

.l-gap--small {
  @include tablet--up {
    grid-gap: $g-spacing-small;
  }
}

section {
  margin: 0 auto;
}
