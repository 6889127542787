.section--navigation--fullscreenDropdown {

  // the state of this checkbox dictates whether the navigation os open or closed
  .navigation__checkbox {
    display: none;
  }

  // this is the element that can be clicked, it's actually just a label for the checkbox
  .navigation__button {
    $navbutton_height: 18px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    height: $navbutton_height;
    width: 27px;
    top: floor(($g-navigation-height - $navbutton_height)/3);
  }

  // build the burger menu from html elements
  .navigation__button__stripe {
    background: $white;
    position: absolute;
    width: 100%;
    height: 4px;
    top: 50%;
    left: 0;
    border-radius: 2px;
    display: block;
    transition: $g-animation-time-fast;

    &:first-child {
      top: 0;
    }

    &:last-child {
      top: 100%;
    }
  }

  // show X - when checkbox is checked
  .navigation__checkbox:checked + .navigation__button {
    .navigation__button__stripe {
      opacity: 0;
      top: 50%;

      &:first-child {
        opacity: 1;
        transform: rotate(405deg);
      }

      &:last-child {
        opacity: 1;
        transform: rotate(-405deg);
      }
    }
  }

  // navigation container
  // normal state - three stripes
  .navigation__checkbox ~ .navigation__container {

    background: transparentize($alt-1, .1);
    position: fixed;
    // we start where the navigationbar ends
    top: $g-navigation-height;
    left: 0;
    width: 100%;
    height: 0;
    transition: $g-animation-time-fast;
    transition-delay: $g-animation-time-fast;
    overflow: hidden;

    > .navigation__container_list {
      text-align: center;
      position: absolute;
      list-style-type: none;
      top: 35%;
      left: 20%;
      right: 20%;

      > .navigation__container__item {
        opacity: 0;
        transition: $g-animation-time-fast;
        transition-delay: 0s;
      }
    }
  }

  .navigation__checkbox:checked ~ .navigation__container {
    height: 100%;
    transition-delay: 0s;
    > .navigation__container_list {
      > .navigation__container__item {
        opacity: 1;
        transition-delay: $g-animation-time-fast;
      }
    }
  }

  //links in navigation
  .navigation__container__item a {
    font-size: $font--size--1;

    @include tablet--up {
      font-size: $font--size--1*1.6;
    }
    text-decoration: none;
    font-family: $g-font-secondary;
    color: $white;
    font-weight: bold;
    display: inline-block;
    padding: $g-spacing-medium;
    line-height: 0.8em;
  }
}

@include phone {
  @include landscape {

    .navigation__container__item {
      float: left;
      list-style: none;
    }
  }
}
