.embed--youtube {

  &__container{

    position: relative;
    margin-top: $g-spacing-extra-large;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    width: 100%;
  }

  &__container iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
