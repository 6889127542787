.section-supporters {
  max-width: $g-max-width;
}

.supporters-list {
  text-align: justify;
  hyphens: auto;
  font-size: ($font--size--2)/2;
}

.supporter-name::after {
  content: ' +';
}

.supporter-name:last-of-type::after {
  content: ' ';
}
