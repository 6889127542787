/* ibm-plex-serif-regular - latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ibm-plex-serif-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Serif'), local('IBMPlexSerif'),
  url('../fonts/ibm-plex-serif-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/ibm-plex-serif-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/ibm-plex-serif-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/ibm-plex-serif-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/ibm-plex-serif-v4-latin-regular.svg#IBMPlexSerif') format('svg'); /* Legacy iOS */
}

/* ibm-plex-serif-700 - latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/ibm-plex-serif-v4-latin-700.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Serif Bold'), local('IBMPlexSerif-Bold'),
  url('../fonts/ibm-plex-serif-v4-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/ibm-plex-serif-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/ibm-plex-serif-v4-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/ibm-plex-serif-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/ibm-plex-serif-v4-latin-700.svg#IBMPlexSerif') format('svg'); /* Legacy iOS */
}

/* barlow-condensed-500 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/barlow-condensed-v1-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Barlow Condensed Medium'), local('BarlowCondensed-Medium'),
  url('../fonts/barlow-condensed-v1-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/barlow-condensed-v1-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/barlow-condensed-v1-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/barlow-condensed-v1-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/barlow-condensed-v1-latin-500.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}

/*
html {
  font-family: $g-body-font;
  font-size: $doc-font-size;
  line-height: $doc-line-height;
}
*/

h1 .h1, h2 .h2, h3, .h3, h4, .h4, h5, .h5 {
  font-family: $g-header-font;
  font-weight: 700;
}

.section__headline {
  padding: $g-spacing-large 0 $g-spacing-small 0;
  line-height: 1.3;
  &--small {
    text-align: left;
    font-size: 1em;
    line-height: 1em;
    padding-bottom: 0;
  }
}

.headline__counter {

  padding: .5em 0 0 0;

  @include tablet--up {
    padding: $g-spacing-large 0 0 0;
  }

  font-weight: 400;
  line-height: 1.3;
  font-size: 1.3em;
}

.section__text {
  padding: $g-spacing-medium 0;
  text-align: justify;
  hyphens: auto;
}
