.counter {
  padding-bottom: $g-spacing-extra-large;
  font-size: ($font--size--1)*1.25;
  font-weight: 700;
  height: $font--size--1;
  line-height: $font--size--1;
  margin-top: -20px;
}

.letter {
  opacity: 0;
  transition: opacity .5s;
  display: inline-block;
  width: 0.7em;

  /* all dots and the last space before the € sign */
  &[data-value="."],
  &[data-value=" "]:nth-last-child(2) {
    width: 0.3em;
  }
}
