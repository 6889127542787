.footer {
  background: $white;
  color: choose-contrast-color($g-color-base);
  font-size: ($font--size--2)/2;
  height: 40px;
  position: absolute;
  width: 100%;

  &__body{
    display: flex;
    margin: 0 auto;

    @include tablet--up {
      width: 300px;
    }

    & li {
      margin: 0 auto;
      list-style: none;
    }

    & a {
      color: $alt-3;
    }
  }
}
