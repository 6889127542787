$background-color: $g-color-muted;

html {
  height: 100%;
}

body {
  background-color: $white;
  color: $g-color-base;

  font: 100%/1.5 $g-body-font;
  font-size: $doc-font-size;
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-align: center;

  padding-top: $g-navigation-height;
}

main {
  min-height: 100%;
  position: relative;
  padding-bottom: $g-spacing-extra-large * 2;
}
