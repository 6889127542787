.language-switch {
  font-family: $g-font-secondary;
  font-size: 1.5em; //h2
}

.language-switch__language::after {
  content: "|";
  color: $white;
}

.language-switch__language:last-child::after {
  content: '';
}

.language-switch__language a {
  text-decoration: none;
  color: $white;
}

.language-switch__language {
  display: inline-block;
}
