.keyvisual {
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}

.keyvisual img {
  padding: 0;
  padding-top: 1em;
  padding-bottom: 1em;

  @include tablet--up {
    padding: 30px 0 40px 0;
    max-width: 550px;
  }
}

.keyvisual__headline {
  font-family: $g-font-secondary;
  margin: 0 auto;
  padding: 0;
  max-width: 380px;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.1;
}

.keyvisual__subline {
  margin: 0 auto;
  padding-top: $g-spacing-small;
  font-family: $g-font-secondary;
  line-height: 1.2;
  font-size: ($font--size--2)*0.9;
}

.keyvisual__link {
  text-decoration: none;
}

.icon__down {
  background-image: url('../img/angle-double-down-solid.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-top: $g-spacing-regular;

  @include tablet--up {
    margin-top: $g-spacing-large;
  }
}

.icon__down--animated {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(5px)
  }
  40% {
    transform: translateY(-15px)
  }
  60% {
    transform: translateY(-8px)
  }
}