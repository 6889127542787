.section-privacy {
  padding-top: ($g-spacing-extra-large)*2;
  max-width: 650px;
}

.privacy__headline {
  padding: $g-spacing-extra-large 0 $g-spacing-regular 0;
}

.privacy__list {
  margin: 0 auto;
  padding: 0 0 $g-spacing-extra-large $g-spacing-extra-large;
  max-width: 500px;
}

.privacy__list__item {
  list-style: circle;
  text-align: left;
}

.privacy__link {
  text-decoration: underline;
  color: $alt-3;
}

.single-paragraph {
  padding-top: ($g-spacing-extra-large)*2;
}

.imprint__list {
  list-style: none;
}

.imprint__list--one-line {
  display: inline-block;
}

.address__company,
.address__name,
.address__street,
.address__zip,
.address__city,
.address__country {
  font-style: normal;
}
