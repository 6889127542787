.socialLink__list {
  list-style-type: none;
}

.socialLink__list__item {

  display: inline-block;
}

.socialLink__list__item img{
  width: 1.5em;
  height: 1.2em;
  margin: 12px 2px 0;
}